<template>
  <b-container fluid class="c-sub px-0 pb-4 pb-lg-6 pb-xl-7">
    <b-row
      v-if="name"
      no-gutters
      class="c-sub-banner position-relative overflow-hidden"
      :style="{
        backgroundImage: `url(${require(`@/assets/banner-${name}.jpg`)})`,
      }"
    />
    <b-row class="mx-3 mx-lg-5 mx-xl-7 mt-4 mt-lg-6 mt-xl-7">
      <b-col
        v-if="hasNav"
        cols="12"
        lg="3"
        class="d-none d-lg-block pl-0 pr-4 pr-lg-5 pr-xl-6"
      >
        <base-sidebar :list="navList" />
      </b-col>
      <b-col cols="12" :lg="hasNav ? 9 : 12" class="px-0">
        <div
          :class="[
            `c-sub-title`,
            `position-relative`,
            `mb-3`,
            `mb-lg-4`,
            `mb-xl-5`,
            hasNav && `border-bottom`,
          ]"
          :style="[!hasNav && { textAlign: 'center' }]"
        >
          <h4 class="mb-0">{{ subTitle || title }}</h4>
          <base-sidebar-dropdown
            v-if="hasNav"
            :list="navList"
            class="c-sub-dropdown d-lg-none position-absolute"
          />
        </div>
        <slot />
      </b-col>
    </b-row>
    <b-pagination-nav
      v-if="pagination && pagination.total && pagination.total > 0"
      :value="pagination.current"
      :link-gen="linkGen"
      :number-of-pages="pagination.total"
      align="center"
      class="mt-4 mt-lg-5 mt-xl-6"
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseSidebar from "@/components/BaseSidebar";
import BaseSidebarDropdown from "@/components/BaseSidebarDropdown";

export default {
  name: "LayoutSub",
  props: {
    name: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    pagination: { type: [Boolean, Object], default: false },
  },
  components: { BaseSidebar, BaseSidebarDropdown },
  computed: {
    ...mapGetters(["navData"]),
    path() {
      return this.$route.path;
    },
    parent() {
      const { meta } = this.$route;
      return meta.parent;
    },
    navList() {
      return this.navData.find((el) => el.key === this.parent).children;
    },
    navTitle() {
      return this.navData.find((el) => el.key === this.parent).name;
    },
    title() {
      const nav = this.hasNav ? this.navList : this.navData;
      const menu = nav.find((el) => el.to === this.path);
      const title = menu ? menu.name : "";
      if (title) document.title = `${title} - 勤智资本`;
      return title;
    },
    hasNav() {
      return this.navList && this.navList.length > 0;
    },
  },
  methods: {
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-sub {
  &-dropdown {
    top: -4px;
    right: 0;
  }

  &-banner {
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-title {
    padding-bottom: $card-spacer-y;
  }

  @include media-breakpoint-up(md) {
    &-banner {
      height: 200px;
    }
  }

  @include media-breakpoint-up(lg) {
    &-banner {
      height: 300px;
    }

    &-title {
      h4 {
        font-size: $h3-font-size;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &-banner {
      height: 400px;
    }

    &-title {
      padding-bottom: $card-spacer-y * 2;

      h4 {
        font-size: $h1-font-size;
      }
    }
  }
}
</style>
