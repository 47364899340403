<template>
  <b-dropdown
    right
    size="lg"
    variant="link"
    no-caret
    toggle-class="text-decoration-none shadow-none"
    menu-class="c-sidebar-dropdown-menu py-0"
  >
    <template #button-content>
      <b-icon icon="caret-down-square" shift-h="8" shift-v="4" />
    </template>
    <b-dropdown-item
      v-for="(el, i) in list"
      :key="i"
      :to="el.to"
      :active="path === el.to"
      link-class="py-2"
    >
      {{ el.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "BaseSidebarDropdown",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
.c-sidebar-dropdown {
  &-menu {
    margin-top: -8px;

    .dropdown-item {
      font-size: $font-size-lg;
    }
  }
}
</style>
