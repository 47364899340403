var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"c-sub px-0 pb-4 pb-lg-6 pb-xl-7",attrs:{"fluid":""}},[(_vm.name)?_c('b-row',{staticClass:"c-sub-banner position-relative overflow-hidden",style:({
      backgroundImage: ("url(" + (require(("@/assets/banner-" + _vm.name + ".jpg"))) + ")"),
    }),attrs:{"no-gutters":""}}):_vm._e(),_c('b-row',{staticClass:"mx-3 mx-lg-5 mx-xl-7 mt-4 mt-lg-6 mt-xl-7"},[(_vm.hasNav)?_c('b-col',{staticClass:"d-none d-lg-block pl-0 pr-4 pr-lg-5 pr-xl-6",attrs:{"cols":"12","lg":"3"}},[_c('base-sidebar',{attrs:{"list":_vm.navList}})],1):_vm._e(),_c('b-col',{staticClass:"px-0",attrs:{"cols":"12","lg":_vm.hasNav ? 9 : 12}},[_c('div',{class:[
          "c-sub-title",
          "position-relative",
          "mb-3",
          "mb-lg-4",
          "mb-xl-5",
          _vm.hasNav && "border-bottom" ],style:([!_vm.hasNav && { textAlign: 'center' }])},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.subTitle || _vm.title))]),(_vm.hasNav)?_c('base-sidebar-dropdown',{staticClass:"c-sub-dropdown d-lg-none position-absolute",attrs:{"list":_vm.navList}}):_vm._e()],1),_vm._t("default")],2)],1),(_vm.pagination && _vm.pagination.total && _vm.pagination.total > 0)?_c('b-pagination-nav',{staticClass:"mt-4 mt-lg-5 mt-xl-6",attrs:{"value":_vm.pagination.current,"link-gen":_vm.linkGen,"number-of-pages":_vm.pagination.total,"align":"center"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }