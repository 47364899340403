<template>
  <b-nav pills vertical>
    <b-nav-item
      v-for="(el, i) in list"
      :key="i"
      :active="path === el.to"
      :to="el.to"
      link-classes="c-sidebar-link py-2"
    >
      {{ el.name }}
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  name: "BaseSidebar",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-sidebar {
  &-link {
    font-size: $font-size-lg;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-lg * 1.25;
    }
  }
}
</style>
